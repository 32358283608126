// import ErrorHandler from "./errorHandler";
import Config from "../../config/Config.ts";
// import { setupInterceptorsTo } from './interceptors.ts'
import axios from "axios";
import {
  check,
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
// setupInterceptorsTo(axios)

const baseUrl = Config.BaseUrl;

export const getDataAdmin = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/admin?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataAdminById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDataAdmin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/admin`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataAdmin = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/admin/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataAdmin = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/admin/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPasswordAdmin = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/admin/reset_password/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const blockAdmin = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/admin/admin/block/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const unBlockAdmin = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/admin/admin/unblock/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/account/admin/forgot_password`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataMember = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/member?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataMemberById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/member/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDataMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/member`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataMember = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/member/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataMember = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/admin/member/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPasswordMember = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/member/reset_password/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const blockMember = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/admin/member/block/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const unBlockMember = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/admin/member/unblock/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataMerchant = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/merchant?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataMerchantById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/merchant/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDataMerchant = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/merchant`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataMerchant = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/merchant/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataMerchant = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/admin/merchant/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPasswordMerchant = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/merchant/reset_password/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const blockMerchant = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/admin/merchant/block/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const unBlockMerchant = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/admin/merchant/unblock/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataRoleAdmin = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/roles/admin?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataRoleAdminById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/roles/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDataRoleAdmin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/roles/admin`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataRoleAdmin = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/roles/admin/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataRoleAdmin = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/admin/roles/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboRoleAdmin = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/roles/admin/combo_find_name/?${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataRoleMember = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/roles/member?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataRoleMemberById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/roles/member/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDataRoleMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/roles/member`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataRoleMember = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/roles/member/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataRoleMember = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/admin/roles/member/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboRoleMember = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/roles/member/combo_find_name/?${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataRoleMerchant = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/roles/merchant?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataRoleMerchantById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/roles/merchant/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDataRoleMerchant = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/roles/merchant`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataRoleMerchant = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/roles/merchant/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataRoleMerchant = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/admin/roles/merchant/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboRoleMerchant = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/roles/merchant/combo_find_name/?${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getServiceName = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/permissions/${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboMember = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/member/combo/name?name=${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboMemberById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/member/combo/userid?userId=${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboMerchant = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/merchant/combo/name?name=${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboMerchantById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/merchant/combo/userid?userId=${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboAdmin = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/admin/combo/name?name=${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigMenu = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/admin`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const companyChangeLogo = (file) => {
  const formData = new FormData();
  formData.append("companyLogo", file);
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/company/change_profile_picture`, formData, {
        headers: {
          Authorization: `Bearer ${check()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMetadataUser = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/metadata/get?userType=${type}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const metadataUser = (type, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/metadata/update?userType=${type}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserAdminCompany = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/account/admin/company`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const memberSelfRegister = (condition) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/company/config/member_self_register?isenable=${condition}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboMenuMerchant = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/merchant/master`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMenuMerchant = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/merchant/get`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateMenuMerchant = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/company/menu/merchant/update`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboMenuMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/member/master`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMenuMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/member/get`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateMenuMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/company/menu/member/update`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboMenuHomeMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/member_home/master`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMenuHomeMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/member_home/get`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateMenuHomeMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/company/menu/member_home/update`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getComboMenuSaldoMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/member_saldo/master`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMenuSaldoMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/member_saldo/get`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateMenuSaldoMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/company/menu/member_saldo/update`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateConfigColor = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/company/config/color`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAdminProfile = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/account/admin/profile`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSuperAdminProfile = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/account/superadmin/profile`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadBanner = (url, file) => {
  const formData = new FormData();
  formData.append("banner", file);
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}${url}`, formData, {
        headers: {
          Authorization: `Bearer ${check()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserSuperAdminCompany = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/account/superadmin/company`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadAdminProfile = (url, file) => {
  const formData = new FormData();
  formData.append("profilePicture", file);
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}${url}`, formData, {
        headers: {
          Authorization: `Bearer ${check()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadUserMember = (file) => {
  const formData = new FormData();
  formData.append("uploadFile", file);
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/member/import/import`, formData, {
        headers: {
          Authorization: `Bearer ${check()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadTemplateUserMember = (valid) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/member/import/download_template?addValidation=${valid}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserTags = (companyId, userType, key) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/account/tags/${companyId}?name=${key}`, {
        headers: {
          userType,
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboTags = (name, data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/account/tags/${data.companyId}?name=${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
          userType: `${data.userType}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataMerchantBankAccount = (id, query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}balance/admin/merchant_bank_account/${id}?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addMerchantBankAccount = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}balance/admin/merchant_bank_account`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateMerchantBankAccount = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}balance/admin/merchant_bank_account/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteMerchantBankAccount = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}balance/admin/merchant_bank_account/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataAdminMenu = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/menu/admin`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllTags = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/tag/search?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createTag = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/tag/create`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateTag = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/tag/update/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteTag = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/admin/tag/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboUser = (name, type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/${type}/combo/name?name=${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadCustomIcon = (name, image) => {
  const formData = new FormData();
  formData.append("uploadFile", image);
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}user/company/menu/member_home/upload?name=${name}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteIconMenu = (name) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/company/menu/member_home/delete?name=${name}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePasswordAdmin = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/account/admin/change_password`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllCustomMenu = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/company/custom_menu/get_all`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postCustomMenu = (
  name,
  redirectUrl,
  redirectType,
  isAuthRequired,
  uploadFile
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("redirectUrl", redirectUrl);
  formData.append("redirectType", redirectType);
  formData.append("isAuthRequired", isAuthRequired);
  formData.append("uploadFile", uploadFile);

  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/company/custom_menu/add`, formData, {
        headers: {
          Authorization: `Bearer ${check()}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCustomMenu = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/company/custom_menu/update/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateImageCustomMenu = (id, uploadFile) => {
  const formData = new FormData();
  formData.append("uploadFile", uploadFile);

  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrl}user/company/custom_menu/change_imageurl${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sortCustomMenu = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/company/custom_menu/sort`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteImageCustomMenu = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/company/custom_menu/remove_imageurl/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteCustomMenu = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/company/custom_menu/remove/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteBanner = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/company/config/remove_banner_${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCompanyFinancial = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/account/admin/company/config/keuangan_perusahaan`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCompanyPasswordStrength = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/info/company/get_password_strength/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const downloadUserMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/member/export/export`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataSubCompany = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/sub_company/manage/find?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDataSubCompanyById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/sub_company/manage/get/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addDataSubCompany = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/sub_company/manage/create`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataSubCompany = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/sub_company/manage/update/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataSubCompany = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/sub_company/manage/delete`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
        data: { subCompanyId: id },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const comboSubCompany = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/sub_company/manage/combo?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAdminSubCompany = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/sub_company/manage/admin/find?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addAdminSubCompany = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/sub_company/manage/admin/add/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteAdminSubCompany = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/sub_company/manage/admin/remove`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
        data: { adminId: id },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addMemberSubCompany = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/sub_company/manage/member/add/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteMemberSubCompany = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}user/sub_company/manage/member/remove`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
        data: { memberIds: id },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getParentMember = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}user/admin/parent_member/find?${createQueryUrl(
          getEntity(createArrayEntityFromObject(query), query),
          "&"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addParentMember = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}user/admin/parent_member/create`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateParentMember = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/parent_member/update/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const activeDeactivateParentMember = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/parent_member/active/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getConfigParentMember = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/parent_member/config/has_parent_member`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setParenMember = (id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}user/admin/member/set_parent_member/${id}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getParentMemberByIdAccount = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/member/get_parent_member/${id}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
